<template>
    <b-table
      v-if="tableData"
      hover
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- id -->
      <template #cell(id)="data">
        <div class="font-weight-bolder">
          {{ data.item.id }}
        </div>
      </template>
      
      <!-- date -->
      <template #cell(date)="data">
        {{ data.item.date }} <span class="font-small-2 text-muted"> {{ data.item.time }}</span> 
      </template>

      <!-- urgency -->
      <template #cell(urgency)="data">
        <div class="d-flex align-items-center">
          <!-- <span :class="'badge badge-pill badge-'+data.item.urgencyStatus"> {{ data.item.urgency }} </span> -->
          <span :class="'badge badge-pill badge-'+(data.item.ul_id == 1 ? 'success' : (data.item.ul_id == 4 ? 'danger' : 'primary'))"> {{ data.item.ul_id == 1 ? 'Routine' : (data.item.ul_id == 4 ? 'Urgent' : '?')}} </span>
        </div>
      </template>

      <!-- advice -->
      <template #cell(policy)="data">
        <div class="batch_container">
          
            <b-badge
              v-if="data.item.adv_id"
              variant="info"
              v-b-popover.hover.top.v-info="{ content: getAdviceName(data.item.adv_id) }"
              title="Advice"
            >
              <feather-icon icon="MessageSquareIcon" />
            </b-badge>        
            <b-badge
              v-if="data.item.exe_id"
              variant="dark"
              v-b-popover.hover.top.v-dark="{ content: getExerciseName(data.item.exe_id) }"
              title="Exercises"
            >
              <feather-icon icon="PlayIcon" />
            </b-badge>                          
        </div>     
      </template>

      <!-- actions -->
      <template #cell(actions)="data">

        <span title="Open results in new window">
          <feather-icon v-if="data.item.uit_id"
            icon="EyeIcon"
            class="cursor-pointer"
            size="16"
            @click="viewReport(data.item.uit_id)"
          />
        </span>

        <span title="Download results (PDF)">
          <feather-icon v-if="data.item.uit_id"
            icon="DownloadIcon"
            class="cursor-pointer"
            size="16"
            style="margin-left:1em;"
            @click="downloadReport(data.item.uit_id)"
          />
        </span>
        
        <span title="Open results in test mode">
          <feather-icon v-if="data.item.uit_id && hasPermission('algorithm')"
            icon="SlidersIcon"
            class="cursor-pointer"
            size="16"
            style="margin-left:1em;"
            @click="viewReportTestmodus(data.item.uit_id)"
          />
        </span>     

     
        <!-- <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown> -->
      </template>

    </b-table>
</template>

<script>
import {
  BCard, 
  BTable, 
  BAvatar, 
  BImg,
  BBadge,
  VBPopover,  
} from 'bootstrap-vue'

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      advices:[],
      exercises:[],
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'uit_id', label: 'RESULT' },
        { key: 'urgency', label: 'URGENCY' },
        { key: 'create', label: 'DATE' },
        { key: 'policy', label: 'POLICY' },
         { key: "actions", label: "ACTIONS" },
      ],
    }
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },  
  methods: {
    getAdviceName(id) {
      var advices = this.advices.filter(a=>a.id==id)
      if (advices.length) return advices[0].name;
      return '';
    },
    getExerciseName(id) {
      var exercises = this.exercises.filter(a=>a.id==id)
      if (exercises.length) return exercises[0].name;
      return '';
    },
    async loadAdvices() {
      var res = await this.callApi('get', '/advices');
      this.advices = res.data.result;
    },
    async loadExercises() {
      var res = await this.callApi('get', '/exercises');
      this.exercises = res.data.result;
    },
		viewReport(uit_id) {
      window.open(process.env.VUE_APP_API_BASE+"/showReport/"+uit_id+".html", "_blank");
		}, 
		viewReportTestmodus(uit_id) {
      window.open(process.env.VUE_APP_API_BASE+"/showReport/"+uit_id+"/testmodus:1.html", "_blank");
		},    
    downloadReport(uit_id) {
      window.open(process.env.VUE_APP_API_BASE+"/showPdfReport/"+uit_id+".pdf", "_blank");
		},    
  },
  mounted() {
    this.loadAdvices()
    this.loadExercises()
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.batch_container .badge {
  margin: 0.25rem 0.5rem 0.25rem 0rem;
}
</style>
