<template>
  <section id="results">
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
    <b-card no-body>
      <h4 class="mt-2 mr-2 ml-2">{{ patientData.firstName }} {{ patientData.surName }} (1958)</h4>
      <b-row class="mt-1 mr-1 mb-1 ml-1">
        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Name</span>
              </th>
              <td class="pb-50">
                {{ patientData.firstName }} {{ patientData.surName }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="DatabaseIcon" class="mr-75" />
                <span class="font-weight-bold">NHS number</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ patientData.nhsNumber }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="FolderIcon" class="mr-75" />
                <span class="font-weight-bold">Gender</span>
              </th>
              <td>
                {{ patientData.gender }}
              </td>
            </tr>            
            <tr>
              <th class="pb-50">
                <feather-icon icon="MapPinIcon" class="mr-75" />
                <span class="font-weight-bold">Postcode</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ patientData.postcode }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">DOB</span>
              </th>
              <td class="pb-50">
                {{ patientData.dob }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>

      <!-- Results Table Card -->
      <h4 class="mt-2 mr-2 mb-2 ml-2">Patient results</h4>
      <results-table :table-data="data.checkResults" />
      <!--/ Results Table Card -->
    </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BTable,
  BCard,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ResultsTable from "../components/ResultsTable.vue";

export default {
  data() {
    return {
      isLoading:false,
      //selected: { title: 'Square' },
      optionUrgency: [{ title: "Urgent" }, { title: "Routine" }],
      optionAdvices: [{ title: "Contact GP" }, { title: "Call 111" }],
      optionExercises: [{ title: "Yes" }, { title: "No" }],
      selectedUrgency: [],
      selectedAdvices: [],
      selectedExercises: [],

      patientData: {
        id: 0,
        firstName: "",
        surName: "",
        nhsNumber: "",
        postcode: "",
        dob: "",
        gender: "",
      },

      data: {
        checkResults: [],
      },
    };
  },
  methods: {
      async loadPatient(id) {
        this.isLoading = true;
        var res = await this.callApi('get', '/patients/'+id)

        this.patientData.firstName = res.data.result.pd_firstname;
        this.patientData.surName = res.data.result.pd_lastname;
        this.patientData.nhsNumber = res.data.result.pd_nhsno;
        this.patientData.postcode = res.data.result.pd_postal;
        this.patientData.gender = res.data.result.pd_gender;
        this.patientData.dob = res.data.result.pd_dob;

        this.data.checkResults = res.data.result.checkResults;
        this.isLoading = false;
      }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BOverlay,
    BFormGroup,
    vSelect,
    ResultsTable,
    BTable,
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadPatient(this.$route.params.id)
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>